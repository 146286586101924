<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      class="py-2"
      :title="page.title"
      :icon="mdiFileCertificateOutline"
    ></BaseBreadcrumb>

    <v-alert text outlined type="info" border="left">
      Sube un documento pdf o jwt para <b>validarlo</b> y así poder comprobar el
      detalle de tus firmas y certificados, o verificar las evidencias.
    </v-alert>

    <v-card>
      <v-col cols="12" class="py-0">
        <file-drop
          v-on:files-selected="uploadFile"
          :progress="progress"
          :action="'validar'"
          :accept="'application/pdf,.jwt'"
        ></file-drop>
      </v-col>
    </v-card>
    <v-container>
      <v-row class="mt-2" v-if="verifyPDF">
        <v-card class="pa-3 w-100">
          <h3 class="text-h6 mb-1 ml-3 text-sm-small">
            {{ `Firmas disponibles ${textIsAllCheckSignatures()}` }}
          </h3>
          <v-divider class="mb-2"></v-divider>
          <v-col
            cols="12"
            class="py-2"
            v-for="(signature, i) in verifyPDF.digitalSignatures.signatures"
            :key="i"
          >
            <v-icon
              :color="signature.valid ? 'green' : 'red'"
              class="ml-2 mr-1"
              >{{ signature.valid ? "mdi-check-bold" : mdiCloseThick }}</v-icon
            >
            <a
              class="cursor-pointer cursor-hover-underline text-sm-xsmall"
              @click="
                onSignatureClick(
                  signature,
                  verifyPDF.bioSignatures.signatures[i],
                  verifyPDF.digitalSignatures.encrypted
                )
              "
              ><v-icon color="primary" class="mr-1 ml-1">{{
                mdiFileCertificateOutline
              }}</v-icon
              >{{ `Firma ${i + 1} ${signature.signatureName}` }}</a
            >
          </v-col>
        </v-card>
      </v-row>
    </v-container>
    <v-dialog scrollable v-model="validateSignatureDetail" width="auto">
      <v-card>
        <v-card-title class="text-h5">
          Ficha detalle
        </v-card-title>

        <v-card-text>
          <DocumentsValidateSheet
            @close="validateSignatureDetail = false"
            :digitalSignature="digitalSignature"
            :bioSignature="bioSignature"
            :encrypted="encrypted"
          ></DocumentsValidateSheet>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="seeSignature = true"
            v-if="bioSignature && bioSignature.widgetJPGB64"
          >
            Ver firma
          </v-btn>

          <v-btn text color="primary" @click="seeCertificated = true">
            Ver certificados
          </v-btn>

          <v-spacer> </v-spacer>
          <v-btn text color="primary" @click="validateSignatureDetail = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="seeSignature" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Firma
        </v-card-title>
        <v-card-text>
          <div class="px-5 mt-4">
            <v-img
              :src="
                'data:image/jpg;base64,' +
                  (bioSignature && bioSignature.widgetJPGB64)
              "
              contain
              class="lightgrey"
            ></v-img>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="seeSignature = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="seeCertificated" max-width="600">
      <v-card>
        <base-card-title
          title="Certificados"
          @onCloseClick="seeCertificated = false"
        >
        </base-card-title>
        <v-card-text>
          <div class="px-5 mt-4" v-if="digitalSignature">
            <template
              v-for="(certificates, i) in digitalSignature.includedCertificates"
            >
              <h4 :key="i">
                <v-icon size="28" color="#848484fc" class="mr-3 mb-1">{{
                  mdiCertificateOutline
                }}</v-icon>
                {{ certificates.commonName }}
              </h4>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FileDrop from "@/components/commons/FileDrop.vue";
import DocumentsValidateSheet from "./DocumentsValidateSheet.vue";
import BaseCardTitle from "@/components/commons/BaseCardTitle";
import axios from "axios";
import {
  mdiCheckDecagramOutline,
  mdiFileCertificateOutline,
  mdiCertificateOutline,
  mdiCloseThick
} from "@mdi/js";
import { FileToBinaryString } from "../../utils/utils";

export default {
  name: "DocumentsValidate",

  components: {
    FileDrop,
    DocumentsValidateSheet,
    BaseCardTitle
  },

  methods: {
    textIsAllCheckSignatures() {
      return this.verifyPDF.digitalSignatures.signatures.some(sig => {
        return !sig.valid;
      })
        ? ""
        : "(todas las firmas son válidas)";
    },
    sortData(list) {
      const _list = list;
      if (_list.length) {
        _list.sort(function(a, b) {
          return a.EffectiveIndex - b.EffectiveIndex;
        });
      }
      return _list;
    },
    uploadFile(file) {
      this.progress = true;
      this.verifyPDF = null;
      this.uploading = false;

      const CancelToken = axios.CancelToken;
      let formData = new FormData();
      formData.append("file", file);
      this.uploading = true;
      let endpoint = String();

      FileToBinaryString(file).then(content => {
        const type = content.slice(0, 4);
        if (type === "%PDF") endpoint = "verify/pdf";
        else if (type === "eyJh") endpoint = "verify/legalaudittrail";

        if (endpoint) {
          axios
            .post(endpoint, formData, {
              headers: { "Content-Type": "multipart/form-data" },
              cancelToken: new CancelToken(c => (this.cancel = c))
            })
            .then(response => {
              if (type === "%PDF") {
                this.verifyPDF = response.data;
                if (this.verifyPDF.digitalSignatures)
                  this.verifyPDF.digitalSignatures.signatures = this.sortData(
                    this.verifyPDF.digitalSignatures.signatures
                  );
                if (this.verifyPDF.bioSignatures)
                  this.verifyPDF.bioSignatures.signatures = this.sortData(
                    this.verifyPDF.bioSignatures.signatures
                  );
              }
              this.$toasted.global.toast_success({
                message: "El documento se ha validado correctamente."
              });
            })
            .catch(error => {
              this.$toasted.global.toast_error({
                message: !axios.isCancel(error)
                  ? "No se ha podido validar el documento"
                  : "El servicio ha sido cancelado"
              });
            })
            .finally(() => {
              this.uploading = false;
              this.progress = false;
            });
        } else {
          this.$toasted.global.toast_error({
            message: "El tipo de archivo debe de ser pdf o jwt"
          });
          this.uploading = false;
          this.progress = false;
        }
      });

      return this;
    },
    onSignatureClick(digitalSignature, bioSignature, encrypted) {
      this.digitalSignature = digitalSignature;
      this.bioSignature = bioSignature;
      this.encrypted = encrypted;
      this.validateSignatureDetail = true;
    }
  },

  data: () => ({
    page: {
      title: "Validar Documento"
    },

    seeCertificated: false,
    seeSignature: false,
    validateSignatureDetail: false,
    verifyPDF: null,
    progress: false,
    uploading: false,
    digitalSignature: null,
    bioSignature: null,
    encrypted: null,
    mdiFileCertificateOutline: mdiFileCertificateOutline,
    mdiCertificateOutline: mdiCertificateOutline,
    mdiCloseThick: mdiCloseThick,
    mdiCheckDecagramOutline: mdiCheckDecagramOutline
  })
};
</script>
<style scoped>
@media (max-width: 500px) {
  .text-sm-small {
    font-size: 1rem !important;
  }
  .text-sm-xsmall {
    font-size: 0.8rem !important;
  }
}
.cursor-hover-underline:hover {
  text-decoration: underline;
}
</style>
