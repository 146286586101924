<template>
  <v-card-text>
    <v-tabs
      v-model="tab"
      :class="{
        'mb-1': true,
        'tabs-validate-sheets': true,
        'tabs-validate-sheets-only-view': !bioSignature.hasBioData
      }"
    >
      <v-tab>
        Información Digital
      </v-tab>
      <v-tab v-if="bioSignature.hasBioData">Información Biométrica</v-tab>
      <v-tabs-items class="tabs-documents-validate-sheet" v-model="tab">
        <v-tab-item>
          <v-container fluid class="px-5 pt-6">
            <h3 class="mb-2">Resumen de validez</h3>
            <div class="px-5">
              <h6 class="body-2 font-color-grey">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  `La firma (realizada por ${
                    digitalSignature.signer.commonName
                  }) es ${digitalSignature.valid ? "VÁLIDA" : "INVÁLIDA"}.`
                }}<v-icon
                  :color="digitalSignature.valid ? 'green' : 'red'"
                  class="ml-1 pb-2"
                  >{{
                    digitalSignature.valid ? "mdi-check-bold" : mdiCloseThick
                  }}</v-icon
                >
              </h6>
              <h6 class="body-2 font-color-grey">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  `Hora de firma: ${
                    getTSPInfo
                      ? getTSPInfo.timestamp
                      : digitalSignature.localSigningTime
                  }.`
                }}
              </h6>
              <h6 class="body-2 font-color-grey" v-if="digitalSignature.reason">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{ `Motivo: ${digitalSignature.reason}` }}
              </h6>
              <h6
                class="body-2 font-color-grey"
                v-if="digitalSignature.location"
              >
                <v-icon color="black">mdi-circle-small</v-icon>{{ "Ubicación: "
                }}<a
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="digitalSignature.location"
                  >{{ digitalSignature.location }}</a
                >
              </h6>
              <h6 class="body-2 font-color-grey" v-if="digitalSignature.author">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{ `Autor: ${digitalSignature.author}` }}
              </h6>
              <h6
                class="body-2 font-color-grey"
                v-if="digitalSignature.contactInfo"
              >
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  `Información de contacto: ${digitalSignature.contactInfo}`
                }}
              </h6>
              <h6
                class="body-2 font-color-grey"
                v-if="digitalSignature.productID.toLowerCase() !== unknown"
              >
                <v-icon color="black">mdi-circle-small</v-icon
                >{{ `Producto: ${digitalSignature.productID}` }}
              </h6>
            </div>

            <v-divider class="mt-3 mb-3"></v-divider>

            <h3 class="mb-2">Información adicional</h3>
            <div class="px-5">
              <h6 class="body-2 font-color-grey" v-if="digitalSignature.valid">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  "No ha habido modificaciones en el documento desde que se firmó."
                }}
              </h6>
              <h6 class="body-2 font-color-grey">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{ certificateAllowded() }}
              </h6>
              <h6 class="body-2 font-color-grey">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  getTSPInfo
                    ? `La firma incluye una marca de hora incrustada. Marca de hora: ${getTSPInfo.timestamp}`
                    : `La firma se validó a partir de la hora de firma: ${digitalSignature.localSigningTime}`
                }}
              </h6>

              <template v-if="getTSPInfo">
                <h6 class="body-2 font-color-grey">
                  <v-icon color="black">mdi-circle-small</v-icon
                  >{{
                    `Número de serie de la respuesta de sello de tiempo: ${getTSPInfo.serialNumber}`
                  }}
                </h6>
                <h6 class="body-2 font-color-grey">
                  <v-icon color="black">mdi-circle-small</v-icon
                  >{{
                    `Semilla de respuesta que retorna TSA: ${getTSPInfo.responseNonce}`
                  }}
                </h6>
                <h6
                  class="body-2 font-color-grey"
                  v-if="getTSPInfo.tsaName.toLowerCase() !== unknown"
                >
                  <v-icon color="black">mdi-circle-small</v-icon
                  >{{ `Nombre TSA: ${getTSPInfo.tsaName}` }}
                </h6>
              </template>

              <h6 class="body-2 font-color-grey">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  `El certificado del firmante es ${
                    digitalSignature.signerValid ? "válido" : "inválido"
                  } y ${
                    digitalSignature.hasRevocationInfo ? "" : "no"
                  } contiene información de revocación.`
                }}
              </h6>
            </div>

            <v-divider class="mt-3 mb-3"></v-divider>

            <h3 class="mb-2">Información avanzada</h3>
            <div class="px-5">
              <h6 class="body-2 font-color-grey">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  `Algoritmo hash: ${digitalSignature.otherSignatureInfo.hashAlg}`
                }}
              </h6>
              <h6 class="body-2 font-color-grey">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  `Tipo de firma: ${
                    SignatureType[
                      digitalSignature.otherSignatureInfo.signatureType
                    ]
                  }`
                }}
              </h6>
              <h6 class="body-2 font-color-grey">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  `Forma PAdES de la firma: ${
                    SignatureForm[digitalSignature.signatureForm]
                  }, de tipo: ${
                    SignatureFormPAdES[digitalSignature.signatureFormPAdES].code
                  } (${
                    SignatureFormPAdES[digitalSignature.signatureFormPAdES].text
                  })`
                }}
              </h6>
            </div>

            <v-divider class="mt-3 mb-3"></v-divider>

            <h3 class="mb-2">Visualización de la firma digital</h3>
            <div class="px-5">
              <h6 class="body-2 font-color-grey">
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  digitalSignature.otherSignatureInfo.widgetVisible
                    ? "La firma " +
                      `${
                        digitalSignature.otherSignatureInfo.widgetShowOnAllPages
                          ? `se muestra en todas las páginas`
                          : `se muestra en la página ${digitalSignature.otherSignatureInfo.widgetPage}`
                      }.`
                    : "Firma invisible."
                }}
              </h6>
              <h6
                class="body-2 font-color-grey"
                v-if="digitalSignature.otherSignatureInfo.widgetVisible"
              >
                <v-icon color="black">mdi-circle-small</v-icon
                >{{
                  `Características widget: altura: ${digitalSignature.otherSignatureInfo.widgetHeight}, 
                  anchura: ${digitalSignature.otherSignatureInfo.widgetWidth},
                  Posición X: ${digitalSignature.otherSignatureInfo.widgetX},
                  Posición Y: ${digitalSignature.otherSignatureInfo.widgetY}`
                }}
              </h6>
            </div>
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="bioSignature.hasBioData">
          <v-container fluid class="px-5 pt-6">
            <h6
              class="body-2 font-color-grey mt-2"
              v-if="!bioSignature.decrypted"
              style="color: darkorange;"
            >
              {{
                "Atención: Por seguridad, los datos biométricos de esta firma están protegidos con cifrado asimetrico."
              }}
            </h6>
            <div v-else>
              <h3 class="mb-2">Firma electrónica</h3>

              <div class="px-5">
                <h6
                  class="body-2 font-color-grey"
                  v-if="bioSignature.certEncSerialNumber"
                >
                  <v-icon color="black">mdi-circle-small</v-icon
                  >{{
                    `Número de serie del certificado de cifrado: ${bioSignature.certEncSerialNumber}.`
                  }}
                </h6>
                <h6
                  class="body-2 font-color-grey"
                  v-if="bioSignature.certEncIssuerInfo"
                >
                  <v-icon color="black">mdi-circle-small</v-icon
                  >{{
                    `Información del emisor del certificado de cifrado: ${bioSignature.certEncIssuerInfo}.`
                  }}
                </h6>
                <h6 class="body-2 font-color-grey" v-if="bioSignature.algEnc">
                  <v-icon color="black">mdi-circle-small</v-icon
                  >{{ `Algoritmo de cifrado: ${bioSignature.algEnc}.` }}
                </h6>
                <h6
                  class="body-2 font-color-grey"
                  v-if="bioSignature.gpsData.gpsValid"
                >
                  <v-icon color="black">mdi-circle-small</v-icon
                  >{{ `GPS: ${bioSignature.gpsData.gpsBulk}.` }}
                </h6>
                <h6 class="body-2 font-color-grey" v-if="bioSignature.isISO">
                  <v-icon color="black">mdi-circle-small</v-icon
                  >{{ `ISO IEC 19794-7: ${bioSignature.isISO ? "SÍ" : "NO"}.` }}
                </h6>
              </div>

              <v-divider class="mt-3 mb-3"></v-divider>

              <h3 class="mb-2">Datos de captura</h3>

              <div class="px-5">
                <h6
                  class="body-2 font-color-grey"
                  v-if="bioSignature.max_Press"
                >
                  <v-icon color="black">mdi-circle-small</v-icon
                  >{{
                    `Características dispositivo: presión máxima: ${bioSignature.max_Press}, 
                    ancho: ${bioSignature.lcD_Width}, 
                    alto: ${bioSignature.lcD_Height}, 
                    max X: ${bioSignature.max_X}, 
                    max Y: ${bioSignature.max_Y}, 
                    margen izda: ${bioSignature.captureAreaTopLeft.x}, 
                    margen drcha: ${bioSignature.captureAreaBottomRight.y}, 
                    margen arriba: ${bioSignature.captureAreaTopLeft.x}, 
                    margen abajo: ${bioSignature.captureAreaBottomRight.y}.`
                  }}
                </h6>
                <h6
                  class="body-2 font-color-grey"
                  v-if="bioSignature.softwareData"
                >
                  <v-icon color="black">mdi-circle-small</v-icon>Software:
                </h6>
                <div
                  v-for="(data, i) in bioSignature.softwareData"
                  :key="'sotw' + i"
                >
                  <h6 class="body-2 font-color-grey pl-6">
                    <v-icon color="grey">mdi-circle-small</v-icon>{{ data }}
                  </h6>
                </div>
                <h6
                  class="body-2 font-color-grey"
                  v-if="bioSignature.hardwareData"
                >
                  <v-icon color="black">mdi-circle-small</v-icon>Hardware:
                </h6>
                <div
                  v-for="(data, i) in bioSignature.hardwareData"
                  :key="'hard' + i"
                >
                  <h6 class="body-2 font-color-grey pl-6">
                    <v-icon color="grey">mdi-circle-small</v-icon>{{ data }}
                  </h6>
                </div>
              </div>
            </div>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card-text>
</template>
<script>
import {
  SignatureFormPAdES,
  SignatureType,
  SignatureForm
} from "@/utils/enums";
import { mdiCloseThick } from "@mdi/js";

export default {
  name: "DocumentsValidateSheet",
  components: {},

  computed: {
    getTSPInfo() {
      return (
        (this.digitalSignature.hasTimestamp &&
          this.digitalSignature.tspInfo &&
          this.digitalSignature.tspInfo.length &&
          this.digitalSignature.tspInfo[0]) ||
        null
      );
    }
  },

  methods: {
    certificateAllowded() {
      const fill = this.digitalSignature.otherSignatureInfo.allowedFillInForms;
      const comment = this.digitalSignature.otherSignatureInfo.allowedComment;
      const anyAllow = fill || comment;
      const allAllow = fill && comment;
      return `El certificador especificó que ${
        anyAllow
          ? `${fill ? "se permite rellenar el formulario y firmar," : ""} ${
              allAllow ? " y" : ""
            } ${
              comment ? "se permite comentar el documento," : ""
            } pero no realizar ningún otro cambio. `
          : "no se permite realizar ningún cambio"
      }`;
    }
  },
  props: {
    bioSignature: Object,
    digitalSignature: Object,
    encrypted: Boolean
  },
  data: () => ({
    SignatureFormPAdES: SignatureFormPAdES,
    SignatureType: SignatureType,
    SignatureForm: SignatureForm,
    unknown: "unknown",
    tab: null,
    mdiCloseThick: mdiCloseThick
  })
};
</script>
