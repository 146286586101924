export const SignatureFormPAdES = {
  0: { code: "Unknown", text: "no es PAdES o forma no soportada" },
  1: { code: "BES", text: "Básica" },
  2: { code: "EPES", text: "Con políticas" },
  3: { code: "T", text: "con sello de tiempo" },
  4: { code: "C", text: "con información de certificados" },
  5: { code: "X", text: "con información de revocación" },
  6: { code: "XL", text: "información completa de revocación" },
  7: { code: "A", text: "archivo" }
};

export const SignatureType = {
  0: "Document", //Normal
  1: "MDP", //Certificada (MDP)
  2: "UsageRights", // Derechos de uso
  3: "Object", //Object
  4: "Unknown" //Desconocida
};

export const SignatureForm = {
  0: "PAdES_Standard", //PAdES estandar
  1: "PAdES_Advanced", // PAdES avanzada
  2: "PAdES_Archive", // PAdES archivo
  3: "Basic" //Básica
};
